import React, { FC, useState } from "react";
import { ModalBase, ModalProps } from "../ModalBase";
import { ConcatShortcut } from "../ConcatShortcut";
import {
  APIs,
  apiGetV2,
  apiPostV2,
  apiPutV2,
  authGetV2,
  authPostV2,
  authPutV2,
} from "../../apiV2";

interface SubmitPayloadOptions {
  editMethod?: "POST" | "PUT";
}

type SubmitPayloadType = object | FormData;

type SubmitPayloadWithOptions = [SubmitPayloadType, SubmitPayloadOptions];

export enum ModalActions {
  add,
  edit,
  delete,
}

export interface ModalCadastroProps {
  isOpen: boolean;
  toggle: (action: any) => void;
  action: ModalActions;
  title?: any;
  size?: ModalProps["size"];
  onOpen?: (action: any, params: any) => void;
  onClose?: (action: any) => void;
  submitPayload: (
    action: any
  ) => SubmitPayloadType | SubmitPayloadWithOptions | false;
  headerCheck?: any;
  routesBase?: string;
  number?: any;
  selected?: any;
  notifyEvent?: (action?: ModalActions) => void;
  fetchData?: (e: any) => void;
  autoTitle?: boolean;
  onKeyDown?: any;
  paramsName?: any;
  footerActions?: any;
  concatShortcut?: boolean;
  concatModelName?: string;
  bodyStyle?: React.CSSProperties;
  footerStyle?: React.CSSProperties;
  headerStyle?: React.CSSProperties;
  useApi?: APIs;
  children?: any;
  returnIdOnAdd?: boolean;
}

export const ModalCadastroV2: FC<ModalCadastroProps> = ({
  isOpen,
  toggle,
  action,
  title = "Sem Título",
  size = "lg",
  onOpen,
  onClose,
  fetchData,
  submitPayload,
  headerCheck,
  routesBase,
  number = "Sem Número",
  selected,
  notifyEvent,
  autoTitle = true,
  onKeyDown,
  paramsName,
  footerActions,
  concatShortcut,
  concatModelName,
  bodyStyle,
  footerStyle,
  headerStyle,
  useApi = APIs.interno,
  children,
  returnIdOnAdd = false,
}) => {
  const [loading, setLoading] = useState(false);
  const [loadingFetchInternal, setLoadingFetchInternal] = useState(false);

  const getFunc = useApi === APIs.interno ? apiGetV2 : authGetV2;
  const postFunc = useApi === APIs.interno ? apiPostV2 : authPostV2;
  const putFunc = useApi === APIs.interno ? apiPutV2 : authPutV2;

  const innerFetchData = async () => {
    const [ok, data] = await getFunc(
      `${routesBase}/buscar/${selected}/`,
      undefined,
      { errorMessage: concatModelName !== "unidade" }
    );
    if (ok) {
      fetchData!(data);
      return true;
    } else {
      return false;
    }
  };

  const buscarParametros = async () => {
    if (paramsName) {
      setLoadingFetchInternal(true);
      return await getFunc(`/tela/${paramsName}/`);
    } else {
      return [true, undefined];
    }
  };

  const onBeforeOpen = async () => {
    setLoadingFetchInternal(true);
    const [paramOk, paramRet] = await buscarParametros();

    if (action === ModalActions.edit && fetchData) {
      if (!(await innerFetchData())) {
        if (concatShortcut) {
          // Exceção no comportamento para o cadastro de unidade
          if (concatModelName === "unidade") {
            action = ModalActions.add;
          }
        } else {
          setLoadingFetchInternal(false);
          toggle(ModalActions.add);
          return;
        }
      }
    }
    setLoadingFetchInternal(false);
    if (onOpen) onOpen(action, paramOk ? paramRet : undefined);
  };

  const handleSubmit = async () => {
    debugger;
    const payloadOptions = submitPayload(action);

    const payload =
      payloadOptions instanceof Array
        ? (payloadOptions as SubmitPayloadWithOptions)[0]
        : payloadOptions;

    const options =
      payloadOptions instanceof Array
        ? (payloadOptions as SubmitPayloadWithOptions)[1]
        : null;

    const editFunc = options?.editMethod === "POST" ? postFunc : putFunc;

    if (payload) {
      setLoading(true);

      let [ok, ret] =
        action === ModalActions.add
          ? await postFunc(`${routesBase}/incluir/`, payload)
          : await editFunc(`${routesBase}/alterar/`, payload);

      if (ok) {
        if (notifyEvent) {
          debugger;
          if (concatShortcut) {
            if (concatModelName === "unidade") {
              ret = (payload as any).codigo;
            } else {
              if (ret.id_cadastrado) {
                ret = ret.id_cadastrado;
              }
            }
            notifyEvent(action === ModalActions.add ? ret : selected);
          } else {
            if (returnIdOnAdd && ret.id_cadastrado) {
              ret = ret.id_cadastrado;
            }
            notifyEvent(
              action === ModalActions.add && returnIdOnAdd ? ret : action
            );
          }
        }

        toggle(action);
      }
      setLoading(false);
    }
  };

  return (
    <>
      {concatShortcut && concatModelName && (
        <ConcatShortcut
          concatModelName={concatModelName}
          onClick={() =>
            toggle(
              [0, "", null, undefined].includes(selected)
                ? ModalActions.add
                : ModalActions.edit
            )
          }
        />
      )}
      <ModalBase
        isOpen={isOpen}
        size={size}
        toggle={() => toggle(ModalActions.add)}
        title={
          <>
            {autoTitle &&
              (action === ModalActions.add ? "Inclusão de " : "Alteração de ") +
                " "}
            {title}
          </>
        }
        headerCheck={headerCheck}
        number={number}
        onConfirm={handleSubmit}
        loadingConfirm={loading}
        onKeyDown={onKeyDown}
        footerActions={footerActions}
        bodyStyle={bodyStyle}
        headerStyle={headerStyle}
        footerStyle={footerStyle}
        onBeforeOpen={onBeforeOpen}
        onClosed={() => onClose && onClose(ModalActions.add)}
        loading={loadingFetchInternal}
      >
        {children}
      </ModalBase>
    </>
  );
};
