import React, { useState } from "react";
import { ModalBase } from "./ModalBase";
import { useDispatch, useSelector } from "react-redux";
import { CLOSE_ALERT, SHOW_CONFIRMATION } from "../redux/constants";
import outsideStore from "../redux/store";
import { useRef } from "react";

export const showConfirmation = (msg, onConfirm, onCancel, modalSize) => {
  outsideStore.dispatch({
    type: SHOW_CONFIRMATION,
    msg: msg,
    modalSize: modalSize,
    onConfirm: onConfirm,
    onCancel: onCancel,
  });
};

export const ConfirmationModal = () => {
  const [title, setTitle] = useState("");
  const [number, setNumber] = useState("");
  const dispatch = useDispatch();
  const store = useSelector((state) => state.confirmation);
  const modalRef = useRef(null);

  const toggle = () => {
    if (store.isOpen) {
      dispatch({ type: CLOSE_ALERT });
      setTitle("");
      setNumber("");
    }
  };

  const buscarInfoTela = () => {
    const modals = Array.from(document.getElementsByClassName("modal"));
    if (modals.length > 0) {
      const lastModal = modals[modals.length - 1];

      let lastModalNumber = lastModal.querySelectorAll(`[name='modal-number']`);

      if (lastModalNumber.length > 0) {
        lastModalNumber = lastModalNumber[0].textContent;
        setNumber(
          (lastModalNumber ?? "").replaceAll("[", "").replaceAll("]", "")
        );
      }

      let lastModalTitle = lastModal.querySelectorAll(`[name='modal-title']`);

      if (lastModalTitle.length > 0) {
        lastModalTitle = lastModalTitle[0].textContent;
        setTitle(lastModalTitle);
      }
    } else {
      let pagenumber = document.getElementsByName("page-number");
      let screentitle = document.getElementsByName("screen-title");
      if (pagenumber.length > 0) {
        pagenumber = pagenumber[0].textContent;
        setNumber((pagenumber ?? "").replaceAll("[", "").replaceAll("]", ""));
      }
      if (screentitle.length > 0) {
        screentitle = screentitle[0].textContent.split(" [")[0];
        setTitle(screentitle);
      }
    }
  };

  return (
    <ModalBase
      isOpen={store.isOpen}
      toggle={toggle}
      size={store.modalSize}
      title={title}
      number={number}
      footerActions
      onBeforeOpen={buscarInfoTela}
      autoFocus
      ref={modalRef}
      onConfirm={() => {
        if (store.onConfirm) store.onConfirm();
        toggle();
      }}
      confirmButtonText="Sim"
      cancelButtonText="Não"
      onCancel={() => {
        if (store.onCancel) store.onCancel();
        toggle();
      }}
    >
      {store.msg}
    </ModalBase>
  );
};
