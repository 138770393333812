import { FC } from "react";
import { TableCheck } from "../../../../../components/TableCheck";
import { TableColumn } from "../../../../../components/table/TableRow";
import { Table } from "../../../../../components/Table";
import { iconeAlterar } from "../../../../../components/cadastro";
import { naturalSort } from "../../../../../coreUtils";

interface Props {
  data: any;
  setSelected: any;
  alterar: (id: any) => void;
}
export const UsuariosGrid: FC<Props> = ({ data, setSelected, alterar }) => {
  const columns: TableColumn[] = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "colaborador",
      text: "Colaborador",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "nickname",
      text: "nickname",
      align: "left",
      sortable: true,
    },
    {
      dataField: "ativo",
      text: "Ativo",
      align: "center",
      formatter: (c: any) => <TableCheck value={c} />,
      sortable: true,
    },
    {
      dataField: "empresa__nome_fant",
      text: "Empresa",
      align: "left",
      sortable: true,
    },
    {
      dataField: "licenca",
      text: "Licença",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "acessos",
      text: "Acessos",
      align: "left",
      formatter: (c) =>
        c?.map((e: any) => (
          <span
            style={{
              backgroundColor: e === "Master" ? "#5b7dff" : "#dbdbdb",
              color: e === "Master" ? "white" : undefined,
              paddingInline: "0.3rem",
              marginRight: "0.3rem",
              borderRadius: "0.2rem",
            }}
          >
            {e}
          </span>
        )),
    },
    iconeAlterar(alterar) as TableColumn,
  ];
  return (
    <Table
      data={data}
      columns={columns}
      onSelect={setSelected}
      onRowDoubleClick={alterar}
    />
  );
};
