import { FC, useEffect, useState } from "react";
import {
  AsyncComboBox,
  BotaoPesquisar,
  FormCheckbox,
  PageContainer,
} from "../../components";
import { Card, Row } from "reactstrap";
import ImplantacaoService from "../../services/ImplantacaoService";
import { ImplantacaoGrid } from "./components/ImplantacaoGrid";
import { DetalhesImplantacaoModal } from "./detalhes/DetalhesImplantacaoModal";
import { Provider } from "react-redux";
import detalhesStore from "./detalhes/store";
import { OkUsoModal } from "./components/OkUsoModal";
import { ConcluirImplantacaoModal } from "./components/ConcluirImplantacaoModal";
import { useQueryParams } from "../../utils/hooks";

export const Implantacao: FC = () => {
  const query = useQueryParams();
  const queryAction = query.get("action");
  const queryId = query.get("id") ? parseInt(query.get("id")!) : null;
  const queryIdAtualiza = query.get("id_atualiza")
    ? parseInt(query.get("id_atualiza")!)
    : null;
  const querySetor = query.get("setor");

  const [idEmpresa, setIdEmpresa] = useState(null);
  const [mostrarFinalizadas, setMostrarFinalizadas] = useState(false);
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [idAtualizaMostrar, setIdAtualizaMostrar] = useState<any>(null);
  const [setorMostrar, setSetorMostrar] = useState<any>(null);
  const [detalhesOpen, setDetalhesOpen] = useState(false);
  const [okUsoOpen, setOkUsoOpen] = useState(false);
  const [concluirOpen, setConcluirOpen] = useState(false);

  const carregarDados = async () => {
    const params = {
      id_empresa: idEmpresa,
      mostrar_finalizadas: mostrarFinalizadas,
    };
    setLoading(true);
    const [ok, ret] = await ImplantacaoService.listar(params);
    setDados(ok ? ret : []);
    setLoading(false);
  };

  const notifyFecharDetalhes = () => {
    setIdAtualizaMostrar(null);
    setSetorMostrar(null);
    carregarDados();
  };

  const toggleDetalhes = () => setDetalhesOpen(!detalhesOpen);

  const toggleOkUso = () => setOkUsoOpen(!okUsoOpen);

  const toggleConcluir = () => setConcluirOpen(!concluirOpen);

  const mostrarDetalhes = (id: any) => {
    setSelected(id);
    setTimeout(() => {
      toggleDetalhes();
    }, 1);
  };

  const darOkUso = (id: any) => {
    setSelected(id);
    setTimeout(() => {
      toggleOkUso();
    }, 1);
  };

  const concluir = (id: any) => {
    setSelected(id);
    setTimeout(() => {
      toggleConcluir();
    }, 1);
  };

  useEffect(() => {
    if (queryAction === "MEN_ATU") {
      if (queryId && queryIdAtualiza && querySetor) {
        setIdAtualizaMostrar(queryIdAtualiza);
        setSetorMostrar(querySetor);
        mostrarDetalhes(queryId);
      }
    }
  }, [queryAction, queryId, queryIdAtualiza, querySetor]);

  return (
    <PageContainer title="Implantação" number="014">
      <Card body>
        <Row>
          <AsyncComboBox
            md={4}
            concatModelName="empresa"
            label="Empresa"
            isSearchable
            isClearable
            onChange={setIdEmpresa}
          />
          <FormCheckbox
            label="Mostrar Concluídas"
            checked={mostrarFinalizadas}
            onChange={setMostrarFinalizadas}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <OkUsoModal
            isOpen={okUsoOpen}
            toggle={toggleOkUso}
            selected={selected}
            notifyEvent={carregarDados}
          />
          <ConcluirImplantacaoModal
            isOpen={concluirOpen}
            toggle={toggleConcluir}
            selected={selected}
            notifyEvent={carregarDados}
          />
          <Provider store={detalhesStore}>
            <DetalhesImplantacaoModal
              isOpen={detalhesOpen}
              toggle={toggleDetalhes}
              selected={selected}
              notifyEvent={notifyFecharDetalhes}
              idAtualizaMostrar={idAtualizaMostrar}
              setorMostrar={setorMostrar}
            />
          </Provider>
        </Row>
      </Card>
      <Card body>
        <ImplantacaoGrid
          dados={dados}
          mostrarDetalhes={mostrarDetalhes}
          darOkUso={darOkUso}
          concluir={concluir}
          setSelected={(v) => {
            setSelected(v);
          }}
        />
      </Card>
    </PageContainer>
  );
};
