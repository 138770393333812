import * as types from "../constants";

const initialState = {
  isOpen: false,
  msg: "",
  modalSize: "sm",
  onConfirm: null,
  onCancel: null,
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.SHOW_CONFIRMATION:
      return {
        isOpen: true,
        msg: actions.msg,
        modalSize: actions.modalSize ?? "sm",
        onConfirm: actions.onConfirm,
        onCancel: actions.onCancel,
      };
    case types.CLOSE_ALERT:
      return initialState;
    default:
      return state;
  }
}
